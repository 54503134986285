<template>
	<el-dialog :visible.sync="parentVisible" :title="title" onOk width="500px">
		<el-form labelPosition="top" :model="form" :rules="rules" ref="form">
			<el-form-item label="输入该笔订单的订单号继续" prop="orderNo">
				<el-input placeholder="请输入订单号" v-model="form.orderNo" />
			</el-form-item>
		</el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="onCancel">取 消</el-button>
            <el-button type="primary" :loading="confirmLoading" @click="onConfirm">确 定</el-button>
        </div>
	</el-dialog>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: "",
		},
		orderNo: {
			type: String,
			default: "",
		},
        visible: {
			type: Boolean,
			defaultValue: false,
		},
        onOk: {
            type: Function,
            defaultValue: () => null
        }
	},
	data() {
		return {
            confirmLoading: false,
			form: {
				orderNo: "",
			},
			rules: {
				orderNo: [
					{
						validator: (rule, value, callback) => {
							if (!value) {
								return callback(new Error("请输入订单号"));
							}

							if (value !== this.orderNo) {
								return callback(new Error("输入的订单号有误，请重新输入"));
							}

							callback();
						},
					},
				],
			},
		};
	},
    computed: {
        parentVisible: {
			get() {
				return this.visible;
			},
			set(value) {
				this.$emit("update:visible", value);
			},
		},
    },
    watch: {
        visible(newValue) {
            if (!newValue) {
                this.$refs.form.resetFields();
            }
        }
    },
    methods: {
        onCancel() {
            this.$emit("update:visible", false);
        },
        async onConfirm() {
            const valid = await this.$refs.form.validate();

			if (!valid) {
				return;
			}

            this.confirmLoading = true

            try {
                await this.onOk?.()

                this.$emit("update:visible", false);   
            } catch (error) {
                console.error(error)
            } finally {
                this.confirmLoading = false
            }
        }
    }
};
</script>

<style></style>
