import { http } from '@/http/axios.js'

export function getComboList(params) {
  return http({
    url: '/admin/store/combo/list',
    method: 'get',
    params
  })
}

export function calculate(data) {
    return http({
      url: '/admin/store/combo/calculate',
      method: 'post',
      data
    })
  }