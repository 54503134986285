<template>
	<div class="wrapper">
		<admin-title :title="$route.meta.name"></admin-title>
		<el-form :model="form" label-width="auto" :rules="rules" ref="form">
			<el-form-item label="商品售价" prop="salePrice">
				<el-input-number
					class="input-number"
					v-model="form.salePrice"
					placeholder="请输入商品售价"
					:controls="false"
				/>
			</el-form-item>
			<el-form-item label="套餐选择" prop="storeComboId">
				<el-select
					class="select"
					v-model="form.storeComboId"
					placeholder="请选择套餐"
				>
					<el-option
						v-for="item in downPaymentRatioOptions"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					/>
				</el-select>
			</el-form-item>
		</el-form>
		<el-button type="primary" :loading="calculating" @click="submitForm"
			>方案预览</el-button
		>
		<div v-if="calculateResult" class="result">
			<div class="title">费用总览</div>
			<div class="content">
				<div class="content-item">
					<div>首付租金：</div>
					<div>¥ {{ calculateResult.prepayRentPrice }} 元</div>
				</div>
				<div class="content-item">
					<div>总租金：</div>
					<div>¥ {{ calculateResult.rentPrice }} 元</div>
				</div>
				<div class="content-item">
					<div>费率：</div>
					<div>{{ calculateResult.netRate }} %</div>
				</div>
			</div>
			<div class="title">账单详情</div>
			<div class="content bill-content">
				<div
					:class="{ 'content-item': true, 'pre-pay': bill.isPrepay }"
					v-for="(bill, index) in calculateResult.billList"
                    :key="index"
				>
					<div>{{ bill.billName }}：</div>
					<div>¥ {{ bill.billPrice }} 元</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getComboList, calculate } from "./api.js";

export default {
	name: "StoreCalculate",

	data() {
		return {
			calculating: false,
			calculateResult: null,
			comboList: [],
			form: {
				salePrice: undefined,
				storeComboId: undefined,
			},
			rules: {
				salePrice: [
					{ type: "number", required: true, message: "请输入正确的商品售价" },
				],
				storeComboId: [
					{ type: "number", required: true, message: "请选择套餐" },
				],
			},
		};
	},

	computed: {
		downPaymentRatioOptions() {
			return this.comboList.map((item) => ({
				label: `${item.comboName}`,
				value: item.id,
			}));
		},
	},

	async mounted() {
		const reponse = await getComboList();

		this.comboList = reponse;
	},

	methods: {
        async submitForm() {
            if (!this.$refs.form) {
                return;
            }

            const result = await this.$refs.form.validate();

            if (!result) {
                return;
            }

            try {
                this.calculating = true;

                const response = await calculate({
                    salePrice: this.form.salePrice.toString(),
                    storeComboId: this.form.storeComboId,
                });

                this.$set(this, 'calculateResult', response);
            } catch (error) {
                throw error;
            } finally {
                this.calculating = false;
            }
        }
    },
};
</script>

<style scoped lang="scss">
$form-item-width: 240px;

.input-number {
	width: $form-item-width;
	/deep/ .el-input__inner {
		text-align: left;
	}
}

.select {
	width: $form-item-width;
}

.result {
	width: 500px;
	margin-top: 20px;
	padding: 20px;
	border: 1px solid var(--el-border-color);
	border-radius: 4px;

	.title {
		font-size: 16px;
		font-weight: bold;
	}

	.content {
		margin-top: 10px;

		&:not(:last-child) {
			margin-bottom: 10px;
		}

		.content-item {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 28px;

			& > div:first-child {
				display: flex;
				justify-content: flex-end;
				width: 80px;
				color: var(--el-text-color-secondary);
			}
		}
	}

	.bill-content {
		.pre-pay {
			text-decoration: line-through;

			& > div:first-child {
				position: relative;

				&::after {
					position: absolute;
					right: 0;
					top: 50%;
					transform: translate(100%, -50%);
					display: block;
					content: "预付";
					background-color: grey;
					font-size: 12px;
					color: #ffffff;
					padding: 0 4px;
					border-radius: 4px;
				}
			}
		}
	}
}
</style>
