import { render, staticRenderFns } from "./list.vue?vue&type=template&id=989f3c60&scoped=true"
import script from "./list.vue?vue&type=script&lang=js"
export * from "./list.vue?vue&type=script&lang=js"
import style0 from "./list.vue?vue&type=style&index=0&id=989f3c60&prod&lang=scss&scoped=true"
import style1 from "./list.vue?vue&type=style&index=1&id=989f3c60&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "989f3c60",
  null
  
)

export default component.exports