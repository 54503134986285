<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" label-width="100px">
      <el-form-item label="姓名" prop="memberName" :rules="[{ required: true, message: '该项不能为空', trigger: 'blur' }]">
        <el-input v-model="formData.memberName" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="身份证号" prop="identityNo" :rules="[{ required: true, message: '该项不能为空', trigger: 'blur' }]">
        <el-input v-model="formData.identityNo" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="mobile" :rules="[{ required: true, message: '该项不能为空', trigger: 'blur' }]">
        <el-input v-model="formData.mobile" placeholder="请输入" size="small"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="formData.remark" type="textarea" style="width: 350px;" placeholder="请输入" size="small" show-word-limit maxlength="100"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" size="small" @click="save">保存</el-button>
        <el-button type="" size="small" @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addRosterAPI, editRosterAPI, getRosterDetailAPI } from './api';

export default {
  name: 'AddOrEdit',

  data() {
    return {
      formData: {
        memberName: '',
        mobile: '',
        identityNo: '',
        riskLevel: 2,
        remark: '',
      }
    }
  },

  mounted() {
    if (this.$route.params.id) this.getRosterDetail()
  },

  methods: {
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.formData.id) {
            editRosterAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            addRosterAPI(this.formData).then(() => {
              this.$message.success('新增成功')
              this.$router.back()
            })
          }
        }
      })
    },
    // 详情
    async getRosterDetail() {
      this.formData = await getRosterDetailAPI({ id: this.$route.params.id })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .el-form {
    .el-form-item {
      .el-input {
        width: 350px;
      }
    }
  }
}
</style>
