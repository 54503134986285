<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="关键字" class="keywords">
          <el-input placeholder="请输入" v-model="searchData.keywordsValue" class="input-with-select" size="small">
            <el-select v-model="searchData.keywordsName" slot="prepend" placeholder="请选择" size="small">
              <el-option v-for="item in searchTypeReport" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="实付时间">
          <el-date-picker
            style="width:305px;"
            v-model="searchData.timeSlot"
            size="small"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="账期类型" class="bill-type">
          <SelectDict v-model="searchData.billTypeList" dictCode="finance:billType" multiple></SelectDict>
        </el-form-item>
        <el-form-item label="付款类型" class="bill-type">
          <SelectDict v-model="searchData.payType" dictCode="pay:type"></SelectDict>
        </el-form-item>
        <el-form-item label="付款方式" class="bill-type">
          <SelectDict v-model="searchData.payMode" dictCode="pay:mode"></SelectDict>
        </el-form-item>
        <el-form-item label=" " label-width="50px">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <div class="header-btn">
      <el-button
        v-checkbtn="isShowBtn(AUTH_BTN.finance_report_list_add)"
        style="margin-bottom:20px"
        type="primary"
        icon="el-icon-plus"
        size="small"
        @click="$router.push({ name: 'FinanceReportAdd' })"
        >添加流水</el-button
      >
      <el-button
        v-checkbtn="isShowBtn(AUTH_BTN.finance_report_list_export)"
        style="margin-bottom:20px"
        type="primary"
        icon="el-icon-download"
        size="small"
        @click="downloadExcel"
        >导出</el-button
      >
    </div>

    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="pageSize"
    />
  </div>
</template>

<script>
import { authBtnMixin } from '@/mixins/authBtnMixin'
import { searchTypeReport } from '@/enum/dict.js'
import { getListAPI, exportAPI } from './api'
import SelectDict from '@/views/components/select-dict.vue'
const columns = [
  {
    label: '姓名',
    prop: 'memberName',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['memberName']}</p>
    }
  },
  {
    label: '订单号',
    prop: 'orderNo',
    minWidth: '220',
    customRender(h, row) {
      return <p>{row['orderNo']}</p>
    }
  },
  {
    label: '交易单号',
    prop: 'openTradeNo',
    minWidth: '220',
    customRender(h, row) {
      return <p>{row['openTradeNo']}</p>
    }
  },
  {
    label: '审核人员',
    prop: 'reviewUserName',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['reviewUserName']}</p>
    }
  },
  {
    label: '金额',
    prop: 'amount',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['amount']}</p>
    }
  },
  {
    label: '账期类型',
    prop: 'billTypeName',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['billTypeName']}</p>
    }
  },
  {
    label: '付款类型',
    prop: 'payTypeName',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['payTypeName']}</p>
    }
  },
  {
    label: '付款方式',
    prop: 'payModeName',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['payModeName']}</p>
    }
  },
  {
    label: '付款渠道',
    prop: 'payChannelName',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['payChannelName']}</p>
    }
  },
  {
    label: '类型',
    prop: 'amountType',
    minWidth: '100',
    customRender(h, row) {
      return (
        <div>
          <div v-show={row['amountType'] == 1}>收入</div>
          <div v-show={row['amountType'] == 2}>支出</div>
        </div>
      )
    }
  },
  {
    label: '实付时间',
    prop: 'createTime',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['createTime']}</p>
    }
  },
  {
    label: '应付时间',
    prop: 'billDate',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['billDate']}</p>
    }
  },
  {
    label: '添加人',
    prop: 'actionUserName',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['actionUserName']}</p>
    }
  },
  {
    label: '添加时间',
    prop: 'createTime',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['createTime']}</p>
    }
  },
  {
    label: '备注',
    prop: 'remark',
    minWidth: '150',
    customRender(h, row) {
      return <p>{row['remark']}</p>
    }
  }
]
export default {
  name: 'List',
  mixins: [authBtnMixin],
  components: { SelectDict },
  data() {
    return {
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        keywordsName: '',
        keywordsValue: '',
        timeSlot: [],
        billTypeList: [],
        payType: '',
        payMode: '',
      },
      searchTypeReport
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    async getList() {
      const { keywordsName, keywordsValue, billTypeList, payType, payMode, timeSlot } = this.searchData
      let params = {
        page: this.currentPage,
        pageSize: this.pageSize,
        billTypeList: billTypeList.join(),
        payType,
        payMode,
      }
      if (timeSlot && timeSlot.length > 0) {
        params.leftCreateDate = timeSlot[0]
        params.rightCreateDate = timeSlot[1]
      }
      if (keywordsName) {
        params[keywordsName] = keywordsValue
      }
      const res = await getListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },

    downloadExcel() {
      const { keywordsName, keywordsValue, billTypeList, payType, payMode, timeSlot } = this.searchData
      let data = {
        billTypeList,
        payType,
        payMode,
      }
      if (keywordsName) {
        data[keywordsName] = keywordsValue
      }
      if (timeSlot && timeSlot.length > 0) {
        data.leftCreateDate = timeSlot[0]
        data.rightCreateDate = timeSlot[1]
      }
      exportAPI(data)
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header-search {
    .el-form /deep/ {
      .keywords {
        .el-select .el-input {
          width: 100px;
        }
        .el-input-group {
          width: 320px;
        }
        .input-with-select {
          vertical-align: middle;
        }
        .input-with-select .el-input-group__prepend {
          background-color: #fff;
        }
      }
      .bill-type {
        .el-select {
          width: 305px !important;
        }
      }
      .el-date-editor {
        .el-range-separator {
          padding: 0;
        }
        .el-range-input {
          width: 35%;
        }
      }
    }
  }
  .el-divider--horizontal {
    margin: 0 0 20px;
  }
  .header-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
