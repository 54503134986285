<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="手机号">
          <el-input v-model="searchData.mobile" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="身份证号">
          <el-input v-model="searchData.identityNo" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label=" " label-width="50px">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <el-button
      v-checkbtn="isShowBtn(AUTH_BTN.member_white_list_add)"
      style="margin-bottom:20px"
      type="primary"
      icon="el-icon-plus"
      size="small"
      @click="$router.push({ name: 'WhiteAdd' })"
      >新增</el-button
    >
    <tp-table :tableData="list" :columns="columns" :totalNum="total" :current-page.sync="currentPage" :pageSize.sync="pageSize" />
  </div>
</template>

<script>
import { getListAPI, delRosterAPI, changeRosterAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
const columns = [
  {
    label: 'ID',
    prop: 'id',
    minWidth: '100',
    customRender(h, row) {
      return <p>{row['id']}</p>
    }
  },
  {
    label: '姓名',
    prop: 'memberName',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['memberName']}</p>
    }
  },
  {
    label: '身份证号',
    prop: 'identityNo',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['identityNo']}</p>
    }
  },
  {
    label: '手机号',
    prop: 'mobile',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['mobile']}</p>
    }
  },
  {
    label: '备注',
    prop: 'remark',
    minWidth: '200',
    customRender(h, row) {
      return <p>{row['remark']}</p>
    }
  },
  {
    label: '创建时间',
    prop: 'createTime',
    minWidth: '180',
    customRender(h, row) {
      return <p>{row['createTime']}</p>
    }
  },
  {
    label: '操作',
    width: '180',
    fixed: 'right',
    customRender(h, row) {
      return (
        <div>
          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.member_white_list_edit)}
            underline={false}
            type="primary"
            onClick={() => this.$router.push({ name: 'WhiteEdit', params: { id: row['id'] } })}
          >
            编辑
          </el-link>

          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.member_white_list_del)}
            style="margin:0 10px;"
            underline={false}
            type="primary"
            onClick={() => this.handleDel(row['id'])}
          >
            删除
          </el-link>

          <el-link
            disabled={!this.isShowBtn(this.AUTH_BTN.member_white_list_add_black)}
            underline={false}
            type="primary"
            onClick={() => this.changeStatus(row)}
          >
            加入黑名单
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'WhiteRosterList',
  mixins: [authBtnMixin],
  data() {
    return {
      columns,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      list: [],
      searchData: {
        mobile: '',
        identityNo: ''
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  activated(){
    this.getList()
  },
  watch: {
    currentPage() {
      this.getList()
    },
    pageSize() {
      this.getList()
    }
  },
  mounted() {
    // this.getList()
  },

  methods: {
    async getList() {
      const { mobile, identityNo } = this.searchData
      let params = { page: this.currentPage, riskLevel: 1, pageSize: this.pageSize, mobile, identityNo }
      const res = await getListAPI(params)
      this.list = res.list || []
      this.total = res.total || 0
    },
    // 删除
    handleDel(id) {
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delRosterAPI({ id }).then(() => {
            this.getList()
            this.$message.success('删除成功')
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    // 加入黑名单
    changeStatus({ id, riskLevel }) {
      let data = { id, riskLevel: riskLevel == 1 ? 2 : 1 }
      this.$confirm('此操作将该用户加入黑名单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          changeRosterAPI(data).then(() => {
            this.getList()
            this.$message.success('操作成功')
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;
        .el-input {
          width: 250px;
        }
        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }
          .el-range-input {
            width: 35%;
          }
        }
      }
    }
  }
  .el-divider--horizontal {
    margin: 0 0 20px;
  }
}
</style>
