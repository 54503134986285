var render = function render(){var _vm=this,_c=_vm._self._c;return _c('card',{staticClass:"goods-info"},[_c('AdminTitle',{attrs:{"title":"订单信息","size":"20px"}}),_c('div',{staticClass:"goods-info-detail"},[_c('el-image',{staticStyle:{"width":"100px","border-radius":"8px","height":"100px","margin-right":"20px"},attrs:{"src":_vm.detailInfo.orderGoods ? _vm.detailInfo.orderGoods.skuCoverImageUrl : '',"preview-src-list":[_vm.detailInfo.orderGoods ? _vm.detailInfo.orderGoods.skuCoverImageUrl : '']}},[_c('div',{staticStyle:{"background":"#f5f7fa","width":"100px","height":"100px","line-height":"100px","text-align":"center","color":"#c0c4cc"},attrs:{"slot":"error"},slot:"error"},[_vm._v(" 暂无图片 ")])]),_c('div',{staticClass:"goods-info-detail-right"},[_c('div',{staticClass:"goods-title fw700"},[_vm._v(" "+_vm._s(_vm.detailInfo.orderGoods ? _vm.detailInfo.orderGoods.skuTitle : "")+" ")]),_c('div',{staticClass:"goods-tag"},[_c('el-tag',{attrs:{"type":_vm.detailInfo.orderGoods && _vm.detailInfo.orderGoods.goodsPurityName
								? _vm.detailInfo.orderGoods.goodsPurityName == '全新'
									? 'success'
									: 'warning'
								: 'success'}},[_vm._v(_vm._s(_vm.detailInfo.orderGoods ? _vm.detailInfo.orderGoods.goodsPurityName : ""))]),_c('el-tag',{attrs:{"type":"success"}},[_vm._v(_vm._s(_vm.detailInfo.orderTypeName))]),(_vm.detailInfo.enableAlipayFundAuth)?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("免押")]):_vm._e(),_c('el-tag',{attrs:{"type":"info"}},[_vm._v(_vm._s(_vm.detailInfo.statusName))])],1),_c('div',{staticClass:"label"},[_vm._v("订单号："+_vm._s(_vm.$route.params.id))]),_c('div',{staticClass:"label"},[_vm._v("下单时间："+_vm._s(_vm.detailInfo.createTime))])])],1),_c('div',{staticClass:"cost-details"},[_c('div',{staticClass:"cost-title"},[_vm._v("费用明细")])]),_c('card',{staticClass:"goods-info special-card"},[_c('el-row',_vm._l((_vm.goodsInfoList),function(item,index){return _c('el-col',{key:index,attrs:{"span":item.width ? item.width : 8}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(item.label))]),_c('span',{staticClass:"value"},[_vm._v(_vm._s(_vm.detailInfo[item.prop] || "")+_vm._s(item.unit))]),(
							item.edit &&
							item.prop == 'priceRate' &&
							(_vm.detailInfo.orderType == 2 || _vm.detailInfo.orderType == 4) &&
							_vm.isShowBtn(_vm.AUTH_BTN.review_list_detail_update_price_rate)
						)?_c('i',{staticClass:"el-icon-edit",on:{"click":_vm.updatePriceRate}},[_vm._v(" 修改")]):_vm._e(),(
							item.edit &&
							item.prop == 'salePrice' &&
							_vm.isShowBtn(_vm.AUTH_BTN.review_list_detail_update_cost_price)
						)?_c('i',{staticClass:"el-icon-edit",on:{"click":_vm.updatePrice}},[_vm._v(" 修改")]):_vm._e()])}),1)],1),_c('el-dialog',{attrs:{"title":"费率","visible":_vm.priceRateDialog,"width":"800px"},on:{"update:visible":function($event){_vm.priceRateDialog=$event}}},[_c('div',{staticClass:"fei-box"},_vm._l((_vm.storeComboList),function(item,i){return _c('div',{key:i,staticClass:"fei-item"},[_c('div',{staticClass:"title"},[_vm._v("等级"+_vm._s(item.gradeType))]),_c('el-radio-group',{model:{value:(_vm.storeComboData.storeComboId),callback:function ($$v) {_vm.$set(_vm.storeComboData, "storeComboId", $$v)},expression:"storeComboData.storeComboId"}},_vm._l((item.comboList),function(item,i){return _c('el-radio',{key:i,staticStyle:{"margin-bottom":"10px"},attrs:{"label":item.id}},[_vm._v(_vm._s(item.comboName))])}),1)],1)}),0),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){_vm.priceRateDialog = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.savePriceRate}},[_vm._v("确 定")])],1)]),_c('el-dialog',{attrs:{"title":"修改零售价","visible":_vm.priceDialog,"width":"350px"},on:{"update:visible":function($event){_vm.priceDialog=$event}}},[_c('el-form',{ref:"periodForm",attrs:{"model":_vm.priceData,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"新零售价"}},[_c('el-input-number',{staticStyle:{"width":"200px"},attrs:{"size":"small","precision":2,"min":0},model:{value:(_vm.priceData.salePrice),callback:function ($$v) {_vm.$set(_vm.priceData, "salePrice", $$v)},expression:"priceData.salePrice"}})],1)],1),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){_vm.priceDialog = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.savePrice}},[_vm._v("确 定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }